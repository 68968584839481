/*App.css*/
*{
  --primarioClaro: #003087;
  --primarioOscuro: #2d2926;
  --secundario1:#0057b8;
  --secundario2: #0077c8;
  --secundario3: #ffb81c;
  --secundario4: #fdda24;
}

.footerLogin{/* 
  background: rgba(255, 255, 255, 0.4); 
  backdrop-filter: blur(10px);  */
  border-radius: 10px;
  padding: 10px;
  font-family: sans-serif;
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: #fff;
  a{
    color: rgb(16, 201, 84);
    font-weight: 800;
    text-decoration: none;
  }
}

.Aplicacion{
  min-height: 100vh;
  width: 100%;
  display: flex;
}
/*--------------------BARRA LATERAL--------------------*/

.titulo-barra-lateral{
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  border: 0;
  background-color: #fff;
  color: var(--primarioClaro) !important;
}

.barra-lateral{
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 74px);
  width: 160px;
  padding: 1rem;
  color: var(--primarioClaro) !important;
  overflow: auto;
  border-right: 0.1px solid #dee2e6;
}

.contenedor-titulo-barra-lateral {
  display: grid;
  width: 100%;
  margin: 16px 0;
  justify-items: start;
}

.menu{
  display: inline-flex;
  align-items: center;
  border: 0;
  padding-left: 0;
  padding-bottom: 10px;
  color: var(--primarioClaro);
}

.lista-barra-lateral{
  display: grid;
  font-weight: 400;
  font-size: .875em;
  justify-content: start;
  list-style:none;
  padding-left: 10px;
}

.opcion-barra-lateral{
  border-radius: 0.375;
  text-decoration: none;
  display: inline-flex;
  margin: 5px 0;
  color: var(--primarioClaro);
}

.logo-barra-lateral{
  width: 40%;
  margin-bottom: 7px;
}

.conexion-barra-lateral{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 160px;
  height: 85px;
  color: var(--primarioClaro);
  text-align: center;
  font-size: 12px;
  z-index: initial;
  background-color: #fff;
  user-select: none;
  cursor: pointer;
  padding: 5px 0;
  border-top: .1px solid #dee2e6;
  border-right: .1px solid #dee2e6;
  font-weight: 600;
}
/*--------------------PAGINA PRINCIPAL--------------------*/
.contenido-principal{
  position: absolute;
  top:0;
  left: 160px;
  flex-grow: 1;
  width: calc(100% - 160px);
  background-color: var(--secundario2);
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
}

/*--------------------HOME--------------------*/

.Home {
  display: grid;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: var(--secundario2);
  align-content: space-evenly;
  justify-content: center;
  justify-items: center;
}

.contenedor-Home{
  min-height: 35vh;
  min-width: 450px;
  max-width: 50vw;
  display: grid;
  align-content: space-evenly;
  justify-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid #dee2e6;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.175);
  border-radius: 10px;
  background-color:#fff ;
  color: black ;
  margin: 0 !important;
  padding: 0 !important;
  h1,h2{
    color: var(--primarioClaro) ;
  }
}

.logo{
  width: 30vw;
}

.contenedor-ElegirConexion{
  display: grid;
  align-content: space-evenly;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.formulario-Home {
  display: grid;
  width: 300px;
  margin: 10px;
  h3{
    color: var(--primarioClaro) ;
  }
}

.tabla-conexiones-home {
  display: flex;
  justify-content: center;
  align-items: center;
}


.boton-formulario-Home{
  width: 100%;
  margin-top: 10px;
}

.recomendacion{
  justify-self: start;
}
/*--------------------MENU TABLAS--------------------*/

#select-empresas-ABMMod,
#select-conexiones-ABMAlta,
#select-conexiones-ABMMod{
  margin-bottom: 15px;
}

.contenedor-abm {
  min-height: 100vh;
  min-width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  background-color: var(--secundario2);
  align-content: center;
  justify-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.contenedor-abm-RolesYPermisos{
  width: 95% !important;
}

.contenido-contenedor-abm{
  display: flex;
  min-width: 95%;
  min-height: 35%;
  border: 1px solid #dee2e6;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.175);
  border-radius: 10px;
  background-color: white;
  justify-content: space-around;
  align-content: center;
  justify-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin: 20px 10px;
}

.titulo-contenido-contenedor-abm{
  display: inline-flex;
  padding-top: 5px;
  padding-bottom: 5px;
  h3{
    margin: 5px;
    margin-right: 20px;
  }
  button{
    padding: 2px 6px;
    svg{
      height: 26px;
      width: 26px;
    }
  }
}

.titulo-contenido-contenedor-abm-usuarioconexiones{
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  margin-bottom: 5px;
  h3{
    margin: 5px;
    margin-right: 20px;
  }
  button{
    padding: 6px;
    margin: 3px 0;
    svg{
      height: 26px;
      width: 26px;
    }
  }
}

.contenedor-tabla-abm{
  display: grid;
  overflow: auto;
  max-height: 450px;
  background-color: white;
  border-radius: 10px;
  justify-content: center;
  align-content: center;
  justify-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.sticky-thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
} 

.tabla-abm{
  width: 96%;
  thead{
    tr{
      th{
        text-align: center!important;
      }
    }
  }
  tbody{
    tr{
      td{
        text-align: center;
      }
    }
  }
}

.tabla-abm-RolesYPermisos{
  width: 90%;
}

.table-hover {
  --bs-table-bg:#fafafa;
  --bs-table-hover-bg: var(--primarioClaro);
  --bs-table-hover-color: #fff;
  --bs-table-border-color: rgba(0,96,135,.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.conexiones-usuarios-abm{
  --bs-table-hover-bg: #fafafa;
  --bs-table-hover-color: #000;
}

.acciones-tabla-abm {
  --bs-table-hover-bg: #fafafa;
  --bs-table-hover-color: #000;
}

.grupo-botones-abm{
  display:flex;
  flex-wrap: nowrap;
  min-width: 460px;
  justify-content: space-evenly;
  margin-top: 5px;
  margin-bottom: 5px;
}

.grupo-botones-abm .btn{
  width: 138px;
}

.subtitulo-formulario-abm{
  width: 460px;
  text-align: center;
  margin: 10px 0;
}

.contenedor-formulario-abm{
  display: grid;
  width: 460px;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.formulario-abm{
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  width: 90%;
  .input-group{
    width: 400px !important;
  }
}

.formulario-abm-configuracion-categorias {
  display: grid;
  width: 80%;
  justify-content: center;
  align-items: center;
  justify-items: stretch;
}

.activarOpcion{
  margin-right: 5px !important;
}

.MensajeBaja{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 20%;
  min-width: 20%;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.175);
  background-color: white;
  justify-content: center;
  align-content: center ;
  align-items: center;
  position: fixed;
  top: center;
  z-index: 10;
  p{
    text-align: center;
    width: 300px;
    margin-top: 5px;
    word-wrap: break-word;
  }
}

.nav-botones{
  display: flex;
  min-width: 50%;
  height: 40px;
  justify-content: center;
}

.MensajeMod,
.MensajeAlta{
  display: flex;
  min-width: 50%;
  min-height: 50%;
  justify-content: center;
  border: 1px solid #dee2e6;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.175);
  border-radius: 10px;
  background-color: white;
  align-content: center;
  justify-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  h3{
    margin:10px;
    margin-top: 10px ;
  }
}

.AltaUsuarioConexion,
.ModUsuarioConexion{
  display: flex;
  min-width: 50%;
  min-height: 50%;
  justify-content: space-around;
  border: 1px solid #dee2e6;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.175);
  border-radius: 10px;
  background-color: white;
  align-content: center;
  justify-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  h3{
    margin:10px;
    margin-top: 10px ;
  }
}

.Boton-VolverAUsuarios{
  width: 91%;
  display: flex;
  position: relative;
  top: 0;
  button{
    width: 100%;
    margin: 5px 5px 10px 5px;
  }
}

.Botones-Mensaje{
  display: flex;
  button{
    margin: 5px;
    margin-bottom: 10px;
  }
}


/*--------------------MENU 2--------------------*/

.menu2-consulta1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: var(--secundario2);
  h3{
    color: #fff;
  }
  /* padding-top: 20px; */
}

.formulario-menu2-consulta1 {
  min-height: 200px;
  width: 50%;
  padding: 10px;
  display: grid;
  align-content: space-evenly;
  justify-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid #dee2e6;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.175);
  border-radius: 10px;
  background-color: white;
}

.tabla-menu2-consulta1{
  width: 80%;
  margin: 20px 0 ;
  padding: 8px ;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.175);
  border-radius: 10px;
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: #fff;
}

.resultado-menu2-consulta1{
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  min-height: 300px;
  width: 60%;
  border: 1px solid #dee2e6;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.175);
  border-radius: 10px;
  background-color: white;
}

.titulo-menu2{
  font-size: 1.3rem;
}

.contenedor-cargando{
  display: grid;
  height: 100vh;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  color: #fff;
  p{
    margin-top: 10px;
  }
}

.titulo-resultado-menu2{
  font-size: 1.3rem;
  margin-top: 15px;
}

.formatoExcel{
  width: 100%;
  font-size: 12px;
  margin-top: -10px;
}

.cargaExcel{
  width: 100%;
  margin: 10px;
}

.aplicarCambios{
  width: 100%;
  margin-bottom: 15px ;
}

.irArriba{
  display: block;
  position: fixed;
  bottom: 7px;
  right: 5px;
  width: 110px;
  height: 50px;
  color: white;
}

.tabla-sueldos{
  width: 900px;
}
.tabla-fecha{
  width: 110px;
}

.tablaResultado{
  width: 110%;
  user-select: none;
}

.link-tabla-resultados:hover{
  cursor: pointer;
}

.volverHome{
  margin-bottom: 15px;
}



/*--------------------PAGINA CON LISTADO--------------------*/


.listado{
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
  align-content: start eslint-disable-next-line; 
}

.tabla{
  margin: 0;
}
